import React, { FC } from 'react';

interface LayoutProps {
  children?: React.ReactNode;
  laptopWidth?: boolean;
}

const Layout: FC<LayoutProps> = ({ children }): JSX.Element => {
  return (
    <div className="o-container">
      <div className="o-grid">
        <div className="o-col-mobile-4 o-col-tablet-6 o-col-laptop-8 o-col-start-laptop-3 mt-huge2x">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
