import React from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../../shared/components/back-button/back-button.component';
import Layout from '../../shared/components/layout/layout.component';
import CustomerSupportCard from './components/customer-support-card/customer-support-card.component';
import ErrorMessage from './components/error-message/error-message.component';
import Finished from './components/finished/finished.component';
import Header from './components/header/header.component';
import Pending from './components/pending/pending.component';
import useGetPayments from './hooks/use-get-payments';

const List = (): JSX.Element => {
  const { policyId } = useParams();
  const { handleGetPaymentsByPage, hasError, payments, handleRemoveLastPage } = useGetPayments(policyId);

  const handlePayments = (e, pageNumber = 0): void => {
    e.preventDefault();

    handleGetPaymentsByPage(pageNumber);
  };

  return (
    <div>
      <Layout>
        <BackButton />
        <Header />
        {hasError && <ErrorMessage {...{ handlePayments }} />}
        {!hasError && payments.length > 0 && (
          <>
            <div className="d-flex flex-column mb-huge2x">
              <Pending {...{ payments }} />
              <Finished {...{ handlePayments, handleRemoveLastPage, payments }} />
            </div>
            <CustomerSupportCard />
          </>
        )}
      </Layout>
    </div>
  );
};

export default List;
